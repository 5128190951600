import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const staffSalaryApi = createApi({
    reducerPath: "staffSalaryApi",
    baseQuery,
    endpoints: (builder) => ({
        getStaffSalaryList: builder.query({
            query: ({ page, limit, status, staff, startDate, endDate }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (status) {
                    queryParams.append('status', status);
                }

                if (staff.length > 0) {
                    queryParams.append('staff_ids', JSON.stringify(staff));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                const apiUrl = `main/v1/staffs/pay-slip?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["salary"],
        }),
        getAllStaffSalaryList: builder.mutation({
            query: ({ page, limit, status, staff, startDate, endDate }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

        if (status) {
          queryParams.append('status', status);
        }

        if (staff.length > 0) {
          queryParams.append('staff_ids', JSON.stringify(staff));
        }

        if (startDate) {
          queryParams.append('start_date', startDate);
        }

        if (endDate) {
          queryParams.append('end_date', endDate);
        }

        const apiUrl = `main/v1/staffs/pay-slip?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['salary'],
    }),
    paySlipData: builder.mutation({
      query: (body) => ({
        url: `main/v2/staffs/pay-slip/data`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['salary'],
    }),
    createPaySlip: builder.mutation({
      query: (body) => ({
        url: `main/v2/staffs/pay-slip/new`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['salary'],
    }),
    editPaySlip: builder.mutation({
      query: (body) => ({
        url: `main/v1/staffs/pay-slip/edit`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['salary'],
    }),
    getPaySlipByID: builder.query({
      query: (id) => ({
        url: `main/v1/staffs/pay-slip/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['salary'],
    }),
    markAsPaid: builder.mutation({
      query: (body) => ({
        url: `main/v2/staffs/pay-slip/mark-as-paid`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['salary'],
    }),
    deletePaySlip: builder.mutation({
      query: (body) => ({
        url: `main/v1/staffs/pay-slip/delete`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['salary'],
    }),
  }),
});

export const { useGetStaffSalaryListQuery, usePaySlipDataMutation, useCreatePaySlipMutation, useEditPaySlipMutation, useGetPaySlipByIDQuery, useMarkAsPaidMutation,useGetAllStaffSalaryListMutation, useDeletePaySlipMutation } = staffSalaryApi;
